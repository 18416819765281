import React from 'react'

export default function Error() {
  return (
    <>
        <div className='page-header py-5 mb-5 wow fadeIn'>

        </div>

        <main className=' py-5 mb-5 wow fadeIn'>
            <div class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center pt-50">
                                <h1 className='text-danger'>Your Transaction failed...</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </main>
    </>


  )
}
