import React,{useState,useRef, useEffect} from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { baseURL } from '../app/apiCalls';
import { getCarMakes } from '../app/apiCalls';
import { getCarModel } from '../app/apiCalls';
import { cars as CarData } from '../app/cars';
import { createPolicy } from '../app/apiCalls';
import { Link } from 'react-router-dom';

export default function ThirdPartyInssurance() {

    let loadingToast;

    const [fillType, setFillType] = useState("manual");
    const [loading,setLoading] = useState(false);
    const [cars,setCars] = useState([]);
    const [carModels,setCarModels] = useState([]);
    const previewBtnRef = useRef();
    const termsAndPrivacyBtnRef = useRef();
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const [accept, setAccept] = useState(false)


    const [carYears,setCarYears] = useState([]);


    const [formControls, setFormControls] = useState({
        insuranceId: null,
        type:'third-party',
        issurance_type:'Individual',
        name:'',
        email:'',
        phone:'',
        address:'',
        reg_no:'',
        product_code:'TP',
        vehicle_use:'',
        vehicle_make:'',
        vehicle_model:'',
        vehicle_type:'',
        vehicle_year:'',
        vehicle_chassis:'',
        vehicle_engineNumber:'',
        vehicle_color:'',
        vehicle_cost:10000,
        
    })

    const [paymentData, setPaymentData] = useState({
      email: "",
      amount: "", 
      amountString: '',
      insuranceId: '',
    });

    const carColors = [
        "Red",
        "Blue",
        "Green",
        "Yellow",
        "Black",
        "White",
        "Silver",
        "Gray",
        "Orange",
        "Purple",
        "Brown",
        "Gold",
        "Beige",
        "Turquoise",
        "Maroon",
        "Navy",
        "Pink",
        "Lime",
        "Teal",
        "Cyan",
        "Magenta",
        "Olive",
        "Charcoal",
        "Indigo",
        "Ivory",
        "Violet",
        "Burgundy",
        "Bronze",
        "Khaki",
        "Plum",
        "Slate",
        "Tan",
        "Crimson",
        "Aqua",
        "Aquamarine",
        "Coral",
        "Mauve",
        "Peach",
        "Salmon",
        "Sky Blue",
        "Steel Blue",
        "Lavender",
        "Midnight Blue",
        "Rose",
        "Pearl",
        "Emerald",
        "Ruby",
        "Sapphire",
        "Topaz",
        "Amethyst",
        "Opal",
        "Onyx",
        "Pearlescent",
        "Champagne",
        "Chocolate",
        "Lemon",
        "Lilac",
        "Mint Green",
        "Rust",
        "Tangerine",
        "Burnt Orange",
        "Cobalt Blue",
        "Sunset Orange",
        "Forest Green",
        "Moss Green",
        "Midnight Black"
    ];
      

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // You can use 'auto' for an instant scroll
            });

        };
        scrollToTop();

        

        (async () => {
            const cars = await getCarMakes()
            .then((resp)=>{
                console.log(resp)
                setCars(resp)
            })
            .catch((error)=>{
                console.log(error)
            })
          })();
    },[])

    useEffect(()=>{
      termsAndPrivacyBtnRef.current.click();
  },[])

    useEffect(()=>{
        if(formControls.vehicle_make && formControls.vehicle_model){
            let carYears = [];
            let carData = CarData.filter((item) => item.model == formControls.vehicle_model && item.make == formControls.vehicle_make)
            console.log(carData)
            carData.forEach((item) => carYears.push({year:item.year, body_type: JSON.parse(item.body_styles)}))
            setCarYears(carYears);
        }if(formControls.vehicle_make && formControls.vehicle_model && formControls.vehicle_year){
            // let bodyTypes = carYears.find((item) => item.year == formControls.vehicle_year)?.body_type ? carYears.find((item) => item.year == formControls.vehicle_year)?.body_type : [];
            // setCarTypes(bodyTypes);
        }
    },[formControls])

    useEffect(()=>{
      let amount = 0;
      let amountSring = ""
      if(formControls?.type == 'comprehensive' ){
          amount = .05 * parseInt(formControls.vehicle_cost)
          amountSring = "N" + formatAmountWithCommas(amount);
          amount *= 100
      }else if(formControls?.type == 'comprehensive' ){
          amount = .05 * parseInt(formControls.vehicle_cost)
          amountSring = "N" + formatAmountWithCommas(amount);
          amount *= 100
      }
      else if(formControls?.type == 'third-party'){
          amount = 15000
          amountSring = "N15,000"
          amount *= 100
      }
      else if(formControls?.type == 'third-party'){
          amount = 20000
          amountSring = "N20,000"
          amount *= 100
      }
      console.log(formControls)
      setPaymentData({
          email: formControls.email,
          amount: amount, 
          amountString: amountSring,
          insuranceId: formControls.insuranceId
      })
    },[formControls])

    const formatAmountWithCommas = (amount) => {

      // Check if the amount is a valid number
      if (isNaN(amount)) {
        return 'Invalid amount';
      }
      
      // Convert the number to a string and split it at the decimal point
      const parts = amount.toString().split('.');
    
      // Get the whole part (before the decimal point)
      let wholePart = parts[0];
    
      // Add commas for thousands, millions, etc.
      wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
      // If there's a decimal part, add it back
      const formattedAmount = parts.length === 2 ? `${wholePart}.${parts[1]}` : wholePart;
    
      return formattedAmount;
    }

    const getCurrentCarModels = (value) => {
        (async () => {
            const carModels = await getCarModel(value)
            .then((resp)=>{
                console.log(resp)
                setCarModels(resp)
            })
            .catch((error)=>{
                console.log(error)
            })
          })();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        loadingToast = toast.loading('Loading....')

       

        await createPolicy(formControls).then(async (resp)=>{
        //   console.log(resp)

            // formData = {
            //     ...formControls,
            //     "idCardImgUrl":idUrl,
            //     "utilityImgUrl":UtilityUrl,
            //     "policyHolderId":resp?.policyHolderId,
            //     "customerId":resp?.customerId,
            //     "productId":resp?.productId,
            //     "premium":resp?.premium,
            //     "policyId":resp?.policyId,
            //     "policyStatus":resp?.policyStatus,
            //     "policyName":resp?.policyName,
            // }

            // setFormControls(formData)
            // axios.post(baseURL+"/save-insurance",formData)
            //     .then((resp) => {
            //         console.log(resp)
            //         setFormControls({...formData, insuranceId: resp.data?.data?.insurance?.id})
                    toast.dismiss(loadingToast);
                    toast.success("Insurance Saved successfully")
                    setLoading(false)
                    previewBtnRef.current.click();

            //         previewBtnRef.current.click();
            //     }).catch((e) =>{
            //         toast.dismiss(loadingToast);
            //         setLoading(false)
            //         console.log(e)
            //         toast.error(e?.message);
                    
            //     })
        }).catch((e)=>{
            console.log(e)
            toast.error(e?.response?.data?.detail ? e?.response?.data?.detail : e?.message);
        })
        
    }

    const handleProceedToPay2 = async () => {
      setLoading(true);
      loadingToast = toast.loading('Loading....') 
      try {
          let data = new FormData();
          data.append('amount', paymentData.amount);
          data.append('email', paymentData.email);
          data.append('insuranceId', paymentData.insuranceId);
          
          let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: "https://api.insurbetter.com/api/payment/initiate",
              headers: { 
                  'Content-Type': 'multipart/form-data',
              },
              data: data,
              withCredentials: true
          };

          // const response = await axios.post(baseURL+"/payment/initiate", paymentData);
          const response = await axios.request(config);
    
          if (response.data.status === 'success') {
            // Redirect the user to the payment page or show the payment dialog
            console.log(response.data.data)
            console.log(response.data.data.data.authorization_url)
            toast.dismiss(loadingToast);
              setLoading(false)    
              window.location = response.data.data.data.authorization_url;
          } else { 
            // Handle the payment initiation failure
            console.error('Payment initiation failed');
            toast.dismiss(loadingToast);
              setLoading(false)       
          }
        } catch (error) {
          console.error('Payment initiation error:', error);
          toast.dismiss(loadingToast);
          setLoading(false)   
        }
  }

  return (
    <>
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">Buy Insurance</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Buy Insurance
                    </li>
                </ol>
                </nav>
            </div>
        </div>

        <div class="container-xxl py-5">
      <div class="container">
        <div className="row">
          <div className="col-10 offset-1 mb-5">
            <nav class="nav nav-pills flex-column flex-sm-row">
              <Link class="flex-sm-fill text-sm-center nav-link active py-3 text-bold" aria-current="page" to="/third-party-Insurance">Third Party Motor Insurance</Link>
              <Link class="flex-sm-fill text-sm-center nav-link py-3 text-bold" to="/comprehensive-Insurance">Comprehensive Motor Insurance</Link>
            </nav>
          </div>
        </div>
        <div class="row g-5">
          <div class="col-lg-8 wow fadeIn" data-wow-delay="0.1s">
            <h1 class="display-6 mb-5">
              Third Party Motor Insurance
            </h1>
            <p class="mb-4">
              If you have vehicles that ply the Nigerian roads and you want a basic Insurance recognized by law then the third-party motor insurance is for you.
            </p>
            <p class="mb-4">
              Our third-party motor insurance puts your mind at ease. From N15,000.00 per year /(N1,250) monthly, we will protect you in the event of an accident-causing loss to another person or their property or damage to their car. These include mishaps brought on by another person driving your vehicle.
            </p>
            <p class="mb-4">Features</p>
            <ul class="unordered-list mb-5">
                <li>Pays Medical Bills</li>
                <li>Covers damages to other vehicles in Nigeria and West Africa up to the sum of N3,000,000.00</li>
                <li>Financial support to the beneficiary if there is a loss of life</li>
            </ul>
            <form onSubmit={(e)=>handleSubmit(e)}>
              <div class="row g-3">
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Your Full Name"
                      style={formControls.name?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                      required
                      onChange={(e)=>setFormControls({...formControls,name:e.target.value})} defaultValue={formControls.name}
                    />
                    <label for="name">Your Full Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      onChange={(e)=>setFormControls({...formControls,email:e.target.value})} defaultValue={formControls.email}
                      required
                      style={formControls.email?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                      placeholder="Your Email"
                    />
                    <label for="email">Your Email</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Your Phone"
                        onChange={(e)=>setFormControls({...formControls,phone:e.target.value})} 
                        style={formControls.phone?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} 
                        defaultValue={formControls.phone}
                        required
                    />
                    <label for="email">Your Phone</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Your Address"
                        onChange={(e)=>setFormControls({...formControls,address:e.target.value})} 
                        style={formControls.address?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} 
                        value={formControls.address} name="address" 
                        required
                    />
                    <label for="name">Your Address</label>
                  </div>
                </div>
                {/* <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control">
                     <option value="">Please Select Insurance Type</option>
                    </select>
                    
                    <label for="email">Insurance Type</label>
                  </div>
                </div> */}

                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      placeholder="Your Phone"
                      onChange={(e)=>setFormControls({...formControls,vehicle_chassis:e.target.value})} defaultValue={formControls.vehicle_chassis} required
                        style={formControls.vehicle_chassis?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                    />
                    <label for="email">Vehicle Chasis Number</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Your Phone"
                        onChange={(e)=>setFormControls({...formControls,reg_no:e.target.value})} defaultValue={formControls.reg_no} required
                        style={formControls.reg_no?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}
                    />
                    <label for="email">Vehicle Registration Number</label>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Your Phone"
                        onChange={(e)=>setFormControls({...formControls,vehicle_engineNumber:e.target.value})} style={formControls.vehicle_engineNumber?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} defaultValue={formControls.vehicle_engineNumber} required
                    />
                    <label for="email">Vehicle Engine Number</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" onChange={(e)=>{setFormControls({...formControls,vehicle_make:e.target.value});  getCurrentCarModels(e.target.value)}} style={formControls.vehicle_make?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} value={formControls.vehicle_make} required>
                        <option value="">Please Select Vehicle Make</option>
                        {cars?.map((item,index)=>(<option key={index} value={item}>{item}</option>))}
                    </select>
                    <label for="email">Vehicle Make</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" onChange={(e)=>setFormControls({...formControls,vehicle_model:e.target.value})} value={formControls.vehicle_model} style={formControls.vehicle_model?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} required>
                        <option value="">Please Select Vehicle Make, to view Vehicle Models</option>
                        {carModels?.map((item,index)=>(<option key={index} value={item?.Model_Name}>{item?.Model_Name}</option>))}
                    </select>
                    
                    <label for="email">Vehicle Model</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" onChange={(e)=>setFormControls({...formControls,vehicle_type:e.target.value})} 
                                                    defaultValue={formControls.vehicle_type} 
                                                    required
                                                    style={formControls.vehicle_type?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}} >
                        <option value="">Please Select Vehicle Type</option>
                        <option value="Saloon">Saloon</option>
                        <option value="SUV">SUV</option>
                        <option value="Light Truck">Light Truck</option>
                        <option value="Heavy Duty Truck">Heavy Duty Truck</option>
                    </select>
                    
                    <label for="email">Vehicle Type</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" required onChange={(e)=>setFormControls({...formControls,vehicle_color:e.target.value})} defaultValue={formControls.vehicle_color} style={formControls.vehicle_color?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}>
                     <option value="">Please Select Vehicle Color</option>
                     {carColors?.map((item,index)=>(<option key={index} value={item}>{item  }</option>))}
                    </select>
                    
                    <label for="email">Vehicle Color</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" required onChange={(e)=>setFormControls({...formControls,vehicle_year:e.target.value})} defaultValue={formControls.vehicle_year} style={formControls.vehicle_year?.length > 0 ? {border:'1px solid green'}:{border:'1px solid red'}}>
                        <option value="">Please Select Car Year</option>
                        {carYears?.map((item,index)=>(<option key={index} value={item.year}>{item.year}</option>))}
                    </select>
                    
                    <label for="email">Vehicle Year</label>
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary py-3 px-5" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            class="col-lg-6 wow fadeIn"
            data-wow-delay="0.5s"
            style={{minHeight: "450px"}}
          >
            
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-primary d-none" ref={previewBtnRef}   data-bs-toggle="modal" data-bs-target="#exampleModal">
      Launch demo modal
    </button>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" >Preview Quote</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p className='mb-0'>
                <b>Personal Details</b>
            </p>
            <ul className='mb-30'>
                <li>Name: {formControls.name} </li>
                <li>Email: {formControls.email} </li>
                <li>Phone number: {formControls.phone} </li>
                <li>Last Name: {formControls.address} </li>

            </ul>

            <p className='mb-0'>
                <b>Insurance Details</b>
            </p>
            <ul className='mb-50'>
                <li>Registration number: {formControls.reg_no} </li>
                <li>Insurance type: {formControls.type} </li>
                <li>Vehicle Type: {formControls.vehicle_type} </li>
                <li>Vehicle make: {formControls.vehicle_make} </li>
                <li>Vehicle model: {formControls.vehicle_model}</li>
                {formControls.type != "third-party" && <li>Sum insured: N{formatAmountWithCommas(parseInt(formControls.vehicle_cost))}</li>}
            </ul>

            <p className='mb-0'>
                <b>Amount</b>
            </p>
            <h3 className='mb-50'>
                {paymentData.amountString} 
            </h3>


            <div class="form-check">
                <input defaultValue={true} onChange={(e)=> { console.log(e.target.value);setAcceptPolicy(e.target.value)}} class="form-check-input" type="checkbox" id="defaultCheck1" />
                <label class="form-check-label" for="defaultCheck1">
                    I have read and agreed to the <a href='/privacyPolicy' target='_blank' className='text-info'>terms of service and privacy policy</a> 
                </label>
            </div>
        </div>
        <div class="modal-footer">
            {
                loading? 
                (<button type="button" class="btn btn-secondary">Loading</button>) :
                (<button onClick={() => formControls?.type == "third-party" ? handleProceedToPay2() : ("")} type="button" class="btn btn-secondary">Proceeed to pay</button>)
            }                      
        </div>
        </div>
      </div>
    </div>


    <button type="button" class="btn btn-primary d-none" ref={termsAndPrivacyBtnRef}   data-bs-toggle="modal" data-bs-target="#exampleModal2">
      Launch demo modal
    </button>

    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" >Terms and Privacy Notice</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="form-check">
                  <input onChange={(e)=>setAccept(!accept)} class="form-check-input" type="checkbox" value={true} id="defaultCheck1" />
                  <label class="form-check-label" for="defaultCheck1">
                      I acknowledge that I have read and understood Eclat Insage Nigeria Limited’s <a href='/privacyPolicy' style={{color:'black', textDecoration:'underline'}} target='_blank'>Privacy policy</a>. I hereby accept and give consent to the collection, processing, use, and transfer of my personal data to third parties within and outside Nigeria and also accept that any relevant organization in possession of my personal data may disclose such in fulfilment of the contractual relationship between me and Eclat Insage Nigeria Limited for the purposes of insurance inquiries, insurance purchases, marketing, claims management, and any other data processing activities that may arise between myself and Eclat Insage Nigeria Limited.
                      I also accept that my personal data may be disclosed to regulatory authorities within Nigeria. I affirm that I am aware of and understand my rights under the relevant data protection laws in Nigeria and other terms detailed in the data privacy policy of Eclat Insage Nigeria Limited.
                  </label>
              </div>
            </div>
            <div class="modal-footer">
                  

              {
                            accept && <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">I Agree</button>
                        }                  
            </div>
        </div>
      </div>
    </div>
    </>
  )
}
