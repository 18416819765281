import React,{useEffect} from 'react'

export default function About() {
  useEffect(()=>{
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' for an instant scroll
        });
    };
    scrollToTop();
},[])

  return (
    <>
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">About Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">About</li>
                </ol>
                </nav>
            </div>
        </div>

        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div
                    class="position-relative overflow-hidden rounded ps-5 pt-5 h-100"
                    style={{minHeight: "400px"}}
                    >
                    <img
                        class="position-absolute w-100 h-100"
                        src="img/Rec3.webp  "
                        alt=""
                        style={{objectFit: "cover"}}
                    />
                    <div
                        class="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                        style={{width: "200px", height: "200px"}}
                    >
                        <div
                        class="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3"
                        >
                        <h1 class="text-white mb-0">25</h1>
                        <h2 class="text-white">Years</h2>
                        <h5 class="text-white mb-0">Experience</h5>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="h-100">
                    <h1 class="display-6 mb-5">
                        Welcome to Insurbetter - Paperless insurance in minutes!
                    </h1>
                    <p class="fs-5 text-primary mb-4">
                        At Insurbetter, we believe in transforming the insurance landscape by harnessing the power of cutting-edge technology. Our commitment lies in creating innovative solutions that redefine how insurance products are delivered and services are experienced by end customers.
                    </p>
                    <h4 class="display-8 mb-4">
                        About Us
                    </h4>
                    <p class="mb-4">
                        Insurbetter is at the forefront of the Insurtech revolution, seamlessly blending insurance expertise with technological prowess. As a dynamic player in the industry, we pride ourselves on reimagining insurance for the digital age. Our dedicated team is driven by a passion for innovation, customer-centricity, and a vision to make insurance accessible, efficient, and personalized.
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="container-fluid overflow-hidden my-5 px-lg-0">
      <div class="container facts px-lg-0">
        <div class="row g-0 mx-lg-0">
          <div class="col-lg-6 facts-text wow fadeIn" data-wow-delay="0.1s">
            <div class="h-100 px-4 ps-lg-0">
              <h4 class="display-8 mb-4">
                Our Vision:
              </h4>
              <p class="text-light mb-5">
                We envision a future where insurance is not just a safeguard but a seamless and empowering experience. Through the strategic integration of technology, we aim to simplify complex processes, enhance customer engagement, and provide tailored solutions that align with the evolving needs of our clients.
              </p>
              <h4 class="display-8 mb-4">
                What Sets Us Apart:
              </h4>
              <p class="text-light mb-2">
                Tech-Driven Solutions: We leverage the latest technologies, including artificial intelligence, data analytics, and machine learning, to craft insurance products and services that are intelligent, responsive, and agile.
              </p>
              <p class="text-light mb-2">
                Customer-Centric Approach: Our focus is on you – the customer. We strive to create a user-centric insurance experience, ensuring convenience, transparency, and personalized solutions that go beyond conventional norms.
              </p>
              <p class="text-light mb-2">
                Tech-Driven Solutions: We leverage the latest technologies, including artificial intelligence, data analytics, and machine learning, to craft insurance products and services that are intelligent, responsive, and agile.
              </p>
            </div>
          </div>
          <div class="col-lg-6 facts-counter wow fadeIn" data-wow-delay="0.5s">
            <div class="h-100 px-4 pe-lg-0">
              <div class="row g-5">
                <div class="col-sm-12">
                  <h4 class="display-8 mb-4">
                    Our Services:
                  </h4>
                  <ul>
                    <li>Digital Insurance Products: Explore a range of insurance products designed with you in mind, accessible anytime, anywhere.</li>
                    <li>AI-Driven Claims Processing: Experience faster, more efficient claims processing through our advanced artificial intelligence systems.</li>
                    <li>Personalized Risk Management: Benefit from tailor-made risk management strategies that align with your unique circumstances and requirements</li>
                    <li>Customer Portal: Access a user-friendly portal for seamless interaction, policy management, and real-time updates</li>
                  </ul>
                  <h4 class="display-8 mb-4">
                    Join Us on the Journey:
                  </h4>
                  <p class=" mb-2">
                    At Insurbetter, we invite you to embark on a journey where insurance meets innovation, and customer experiences are elevated. Whether you're an individual seeking comprehensive coverage or a business aiming for risk management excellence, we have the technology-driven solutions to meet your needs.
                  </p>
                  <p class=" mb-2">
                    Explore the future of insurance with Insurbetter – where technology meets protection, and your peace of mind is our priority.
                  </p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
