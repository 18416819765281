import React, {useEffect} from 'react'

export default function Contact() {
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])
    
  return (
    <>
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                    Contact Us
                    </li>
                </ol>
                </nav>
            </div>
        </div>

        <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <h1 class="display-6 mb-5">
                    If You Have Any Query, Please Contact Us
                </h1>
                
                <form>
                <div class="row g-3">
                    <div class="col-md-6">
                    <div class="form-floating">
                        <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        />
                        <label for="name">Your Name</label>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-floating">
                        <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Your Email"
                        />
                        <label for="email">Your Email</label>
                    </div>
                    </div>
                    <div class="col-12">
                    <div class="form-floating">
                        <input
                        type="text"
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                        />
                        <label for="subject">Subject</label>
                    </div>
                    </div>
                    <div class="col-12">
                    <div class="form-floating">
                        <textarea
                        class="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        style={{height: "100px"}}
                        ></textarea>
                        <label for="message">Message</label>
                    </div>
                    </div>
                    <div class="col-12">
                    <button class="btn btn-primary py-3 px-5" type="submit">
                        Send Message
                    </button>
                    </div>
                </div>
                </form>
            </div>
            <div
                class="col-lg-6 wow fadeIn"
                data-wow-delay="0.5s"
                style={{minHeight: "450px"}}
            >
                <div class="position-relative rounded overflow-hidden h-100">
                <iframe
                    class="position-relative w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.63647941162!2d3.1191485819056073!3d6.548028245267584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1713002544980!5m2!1sen!2sng"
                    frameborder="0"
                    style={{minHeight: "450px", border: 0}}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
                </div>
            </div>
            </div>
        </div>
        </div>
    </>
  )
}
