import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div
      class="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            
            <p>
              Welcome to Insurbetter - Paperless insurance in minutes!
            </p>
            <div class="d-flex pt-2">
              <a class="btn btn-square me-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-square me-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-square me-1" href=""
                ><i class="fab fa-youtube"></i
              ></a>
              <a class="btn btn-square me-0" href=""
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="text-light mb-4">Address</h5>
            {/* <p>
              <i class="fa fa-map-marker-alt me-3"></i>My Address
            </p> */}
            <p><i class="fa fa-phone-alt me-3"></i><a href="tel:+2349024868268">+234 902 486 8268</a></p>
            <p><i class="fa fa-envelope me-3"></i><a href="mailto:service@insurbetter.com">service@insurbetter.com</a></p>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="text-light mb-4">Quick Links</h5>
            <Link class="btn btn-link" to="/">Home</Link>
            <Link class="btn btn-link" to="/about">About Us</Link>
            <Link class="btn btn-link" to="/contact">Contact Us</Link>
            <Link class="btn btn-link" to="/third-party-Insurance">Buy Motor Insurance</Link>
            <Link class="btn btn-link" to="/how-to-buy">How to buy</Link>
          </div>

        </div>
      </div>
      <div class="container-fluid copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a href="#">Insurbetter</a>, All Right Reserved.
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
