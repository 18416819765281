import React,{useState,useRef, useEffect} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

export default function Home () {
    let loadingToast;
    const modalBtnRef = useRef();
    const searchBtnRef = useRef();
    const closeBtnRef = useRef()
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [formControls, setFormControls] =  useState({
        'search_by':'Registration',
        'search_value':''
    });

    const [searchResult, setSearchResult] = useState();
    
    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])

    useEffect(() => {
        testRequest();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        loadingToast = toast.loading('Loading....')

        let postData = {
            sv: formControls.search_value,
            st: formControls.search_by.toUpperCase()+"NUMBER",
            un: 'eil',
            pw :'tpKh7iVx9cxl1ZD',
        }
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://restapiv2.niid.org/api/motorinsurance/verify?sv=${postData.sv}&st=${postData.st}&un=eil&pw=tpKh7iVx9cxl1ZD`,
            
        };
        let request = await axios.request(config)
                        .then((resp) => {
                            console.log(resp)
                            setSearchResult(resp?.data)
                            if(resp?.data?.policyStatus ==  "NOT FOUND"){
                                toast.dismiss(loadingToast);
                                toast.error("Your policy was not found on niid portal, kindly check with your insurance company or buy a valid insurance.");
                                searchBtnRef.current?.click()
                                setLoading(false)
                            }else{
                                console.log(searchBtnRef.current)
                                searchBtnRef.current?.click()
                                toast.dismiss(loadingToast);
                                setLoading(false)
                            }
                           
                        }).catch((e) =>{
                            toast.dismiss(loadingToast);
                            console.log(e)
                            toast.error(e?.message);
                            setLoading(false)
                         }
                    )
    }

    function calculateDaysLeftPercentage(expireDate) {
        // Parse the expireDate string to a Date object
        const expireDateObj = new Date(expireDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const timeDiff = expireDateObj - currentDate;

        // Calculate the number of days left
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Calculate the percentage of days left
        const percentageLeft = (daysLeft / 365) * 100;

        // Ensure the result is within the 0-100 range
        return Math.floor(Math.min(100, Math.max(0, percentageLeft)));
    }

    const testRequest = () => {
        let data = new FormData();
        data.append('amount', 450);
          data.append('email', 450);
          data.append('insuranceId', 450);
    
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.insurbetter.com/api/payment/initiate',
            headers: { 
                'Content-Type': 'multipart/form-data',
                // 'Access-Control-Allow-Origin': '*',
            },
            data: data,
            withCredentials: true // Add withCredentials header
        };
    
        async function makeRequest() {
            try {
                const response = await axios.request(config);
                console.log(JSON.stringify(response.data));
            } catch (error) {
                console.log(error);
            }
        }
    
        makeRequest();
    };


    const buyInsurance = (value,state) => {
        closeBtnRef.current.click();
        navigate(value,{state:state});
    }
    
    return (
        <>
            <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="w-100" src="img/hero1.jpg" alt="Image" style={{height:"80vh", objectFit:"cover"}} />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <h1 class="display-4 text-dark mb-4 animated slideInDown">
                                            All Your Motor Insurance Needs Are Here
                                            </h1>
                                            <p class="fs-5 text-body mb-5">
                                            Check the validity of your current motor insurance, renew an expired policy and buy genuine
                                            </p>
                                            <Link to="/third-party-Insurance" class="btn btn-primary py-3 px-5">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="prev"
                    >
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="next"
                    >
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div
                                class="position-relative overflow-hidden rounded ps-5 pt-5 h-100"
                                style={{minHeight: "400px"}}
                            >
                                <img
                                    class="position-absolute w-100 h-100"
                                    src="img/Rec3.webp  "
                                    alt=""
                                    style={{objectFit: "cover"}}
                                />
                                <div
                                    class="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                                    style={{width: "200px", height: "200px"}}
                                >
                                    <div
                                        class="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3"
                                    >
                                        <h1 class="text-white mb-0">25</h1>
                                        <h2 class="text-white">Years</h2>
                                        <h5 class="text-white mb-0">Experience</h5>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="h-100">

                                <p class="fs-5 text-primary mb-4">
                                    Explore the future of insurance with Insurbetter – where technology meets protection, and your peace of mind is our priority.
                                </p>
                                <div class="row g-4 mb-4">
                                    <div class="col-sm-12">
                                        <div class="d-flex align-items-center  mb-2">
                                            <img class="flex-shrink-0 me-3" width={64} src="img/icon/audit.png" alt="" />
                                            <h5 class="mb-1" >Check Insurance Validity</h5>
                                        </div>
                                        <p class="mb-4">
                                            Checking your insurance validity is important for a number of reasons. Simply “search policy” and we will show you the status of your coverage.
                                        </p>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex align-items-center mb-2">
                                            <img class="flex-shrink-0 me-3" width={64} src="img/icon/authentic.png" alt="" />
                                            <h5 class="mb-1">Buy Genuine Insurance Policy</h5>
                                        </div>
                                        <p class="mb-4">
                                            Whether you are a new driver or a seasoned pro, we have the coverage you need to protect yourself and your vehicle.
                                        </p>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex align-items-center  mb-2">
                                            <img class="flex-shrink-0 me-3" width={64} src="img/icon/reminder.png" alt="" />
                                            <h5 class="mb-1">Set Reminder for Policy Renewal</h5>
                                        </div>
                                        <p class="mb-4 mt-2">
                                            Set a reminder for your car policy renewal and avoid surprises. You can do it right here on our website.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="search" class="container-fluid appointment my-5 py-5 wow fadeIn" data-wow-delay="0.1s" >
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeIn offset-lg-3 text-center" data-wow-delay="0.3s">
                            <h1 class="display-6 text-white mb-2">
                                Verify Your Policy
                            </h1>
                            <p class="text-white mb-1">
                                To check the validity of your Insurance Policy, search by Policy Number or Registration Number.
                            </p>
                            
                        </div>
                        <div class="col-lg-6 wow fadeIn offset-lg-3" data-wow-delay="0.5s">
                            <div class="bg-white rounded p-5">
                                <form onSubmit={(e) =>handleSubmit(e)}>
                                    <div class="row g-3">
                                        <div class="col-sm-12">
                                            <div class="form-floating">
                                                <select style={{fontSize:'16px'}} onChange={(e) => setFormControls({...formControls,'search_by':e.target.value})} required class="form-control">
                                                    <option value="Registration" selected>Vehicle Plate Number</option>
                                                    <option value="Policy" >Policy Number</option>
                                                </select>
                                                <label style={{fontSize:'16px'}}  for="gname">Search By</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-floating">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="cname"
                                                    style={{fontSize:'18px'}} 
                                                    required onChange={(e) => setFormControls({...formControls,'search_value':e.target.value})} placeholder={`Enter ${formControls.search_by ? formControls.search_by :  " Policy / Vehicle Plate " } Number`}
                                                />
                                                <label style={{fontSize:'18px'}}  for="cname">{`Enter ${formControls.search_by == 'Registration'?  'Vehicle Plate' :  " Policy " } Number`}</label>
                                            </div>
                                        </div>
                                        <div class="col-12 text-center">
                                            {loading == false && <button class="btn btn-primary py-3 px-5" type="submit" style={{fontSize:'18px'}} >
                                                Search Policy
                                            </button>}
                                        </div>
                                    </div>
                                </form>
                                
                            </div>
                            <div class="bg-white rounded p-3 d-block d-lg-none mt-5 ">
                                <div class="d-flex align-items-center bg-primary rounded p-3">
                                <img
                                    class="flex-shrink-0 rounded-circle me-3"
                                    src="img/user_Avatar.jpeg"
                                    width={60}
                                    alt=""
                                />
                                    <h5 class="text-white mb-0">Call Us: <a className='text-white' href="mailto:+2349024868268">+2349024868268</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid overflow-hidden my-5 px-lg-0">
                <div class="container facts px-lg-0">
                    <div class="row g-0 mx-lg-0">
                        <div class="col-lg-6 facts-text wow fadeIn" data-wow-delay="0.1s">
                            <div class="h-100 px-4 ps-lg-0">
                                <h1 class="text-white mb-4">Building a Brighter financial Future & Good Support.</h1>
                                <p class="text-light mb-5">
                                    At Insurbetter, we believe in transforming the insurance landscape by harnessing the power of cutting-edge technology. Our commitment lies in creating innovative solutions that redefine how insurance products are delivered and services are experienced by end customers.
                                </p>
                                <p class="text-light mb-5">
                                    Insurbetter is at the forefront of the Insurtech revolution, seamlessly blending insurance expertise with technological prowess. As a dynamic player in the industry, we pride ourselves on reimagining insurance for the digital age. Our dedicated team is driven by a passion for innovation, customer-centricity, and a vision to make insurance accessible, efficient, and personalized.
                                </p>
                                <a href="" class="align-self-start btn btn-secondary py-3 px-5">More Details</a
                                >
                            </div>
                        </div>
                        <div class="col-lg-6 facts-counter wow fadeIn" data-wow-delay="0.5s">
                            <div class="h-100 px-4 pe-lg-0">
                                <div class="row g-5">
                                    <div class="col-sm-6">
                                        <h1 class="display-5" data-toggle="counter-up">2278</h1>
                                        <p class="fs-5 text-primary">Policy Purchase</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h1 class="display-5" data-toggle="counter-up">10,234</h1>
                                        <p class="fs-5 text-primary">Policy Search</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h1 class="display-5" data-toggle="counter-up">1780</h1>
                                        <p class="fs-5 text-primary">Purchase Reminder</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h1 class="display-5" data-toggle="counter-up">34</h1>
                                        <p class="fs-5 text-primary">Team Members</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h1 class="display-6 mb-5">We envision a future where insurance is not just a safeguard but a seamless and empowering experience!</h1>
                            <p class="mb-4">
                                Through the strategic integration of technology, we aim to simplify complex processes, enhance customer engagement, and provide tailored solutions that align with the evolving needs of our clients.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="bg-light rounded h-100 p-3">
                                        <div
                                            class="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3"
                                        >
                                            <img
                                                class="align-self-center mb-3"
                                                src="img/icon/insurance.png"
                                                alt=""
                                                width={64}
                                            />
                                            <h5 class="mb-0">
                                                Digital Insurance Products.</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                    <div class="bg-light rounded h-100 p-3">
                                        <div
                                            class="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3"
                                        >
                                            <img
                                                class="align-self-center mb-3"
                                                src="img/icon/automation.png"
                                                alt=""
                                                width={64}
                                            />
                                            <h5 class="mb-0">AI-Driven Claims Processing</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="bg-light rounded h-100 p-3">
                                        <div
                                            class="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3"
                                        >
                                            <img
                                                class="align-self-center mb-3"
                                                src="img/icon/evaluation.png"
                                                alt=""
                                                width={64}
                                            />
                                            <h5 class="mb-0">Personalized Risk Management</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                    <div class="bg-light rounded h-100 p-3">
                                        <div
                                            class="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3"
                                        >
                                            <img
                                                class="align-self-center mb-3"
                                                src="img/icon/portal.png"
                                                alt=""
                                                width={64}
                                            />
                                            <h5 class="mb-0">Customer Portal</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div
                                class="position-relative rounded overflow-hidden h-100"
                                style={{minHeight: "400px"}}
                            >
                                <img
                                    class="position-absolute w-100 h-100"
                                    src="img/bruce-mars-Gz.webp"
                                    alt=""
                                    style={{objectFit: "cover"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto" style={{maxWidth: "500px"}}>
                        <h1 class="display-6 mb-5">
                            High Performance Services For All Industries.
                        </h1>
                    </div>
                    <div class="row g-4 justify-content-center">

                        <div class="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="service-item rounded h-100 p-5">
                                <div class="d-flex align-items-center ms-n5 mb-4">
                                    <div
                                        class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                                    >
                                        <img
                                            class="img-fluid"
                                            src="img/icon/icon-08-light.png"
                                            alt=""
                                        />
                                    </div>
                                    <h4 class="mb-0">Third Party Motor Insurance</h4>
                                </div>
                                <p class="mb-4">
                                    Third-party car insurance is a type of auto insurance that covers the cost of damages to other people or property in the event of an accident. It is the most basic type of car insurance and is required by law in Nigeria.
                                </p>
                                <a class="btn btn-light px-3" href="">Read More</a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="service-item rounded h-100 p-5">
                                <div class="d-flex align-items-center ms-n5 mb-4">
                                    <div
                                        class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                                    >
                                        <img
                                            class="img-fluid"
                                            src="img/icon/icon-07-light.png"
                                            alt=""
                                        />
                                    </div>
                                    <h4 class="mb-0">Comprehensive Motor Insurance</h4>
                                </div>
                                <p class="mb-4">
                                    Comprehensive car insurance is a type of auto insurance that covers your car for damage caused by events other than a collision with another vehicle.
                                </p>
                                <a class="btn btn-light px-3" href="">Read More</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-primary d-none"  ref={searchBtnRef}  data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" >Search Result</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {searchResult?.policyStatus == "EXPIRED" && <h3 className=''>Your Insurance Policy has <b className='text-danger'>EXPIRED</b></h3>}

                            {searchResult?.policyStatus == "NOT FOUND" && <h5 className=''>
                                Your Insurance Policy was <b className='text-danger text-uppercase'>not found</b> on NIID portal, kindly check with your insurance company or  
                                <div className='d-inline dropdown'>
                                    <a className='btn-link dropdown-toggle' href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Buy Motor Insurance</a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                        <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                    </div>
                                </div>          
                            </h5>}

                            {searchResult?.policyStatus == "OK" && <ul className='mb-30'>
                                <li>Policy Status: <span className='text-success text-bold ' style={{fontSize:"20px"}}>Your Policy is Active</span> </li>
                                <li>Registration no: <b>{searchResult?.regNo}</b> </li>
                                <li>Policy no: <b>{searchResult?.policyNumber}</b></li>
                                <li>Chassis no: <b>{searchResult?.chassis}</b></li>
                                <li>Date Issuerd: <b>{searchResult?.issueDate}</b></li>
                                <li>Expiry Date: <b>{searchResult?.expiryDate}</b></li>
                                <li style={{display:"flex", alignItems:'center'}}>
                                    <p style={{flexShrink: 0, marginRight:"15px", marginBottom:"0px", color:'#212529'}}> Expiry Status:  </p>  
                                    <div style={{display: "inline-block", width:"100%"}} class=" progress" role="progressbar" aria-label="Danger example" aria-valuenow={calculateDaysLeftPercentage(searchResult?.expiryDate)} aria-valuemin="0" aria-valuemax="100">
                                        <div class={(calculateDaysLeftPercentage(searchResult?.expiryDate) > 20? 'bg-success ':'bg-danger ')+ "progress-bar "} style={{width: calculateDaysLeftPercentage(searchResult?.expiryDate) +"%"}}>{calculateDaysLeftPercentage(searchResult?.expiryDate)}%</div>
                                    </div>
                                </li>
                                <li>Insurance Policy Type: <b>{searchResult?.coverType == "T"? "Third Party Motor Insurance": "Comprehensive Motor Insurance" } </b></li>
                                <li>Vehicle make: <b>{searchResult?.vehicleMake}</b></li>
                                <li>Vehicle model: <b>{searchResult?.vehicleModel}</b></li>
                            </ul>}
                        </div>
                        <div class="modal-footer">
                            {/* {
                                loading? 
                                (<button type="button" class="btn btn-secondary">Loading</button>) :
                                (<button onClick={() => formControls?.type == "third-party" ? handleProceedToPay2() : ("")} type="button" class="btn btn-secondary">Proceeed to pay</button>)
                            }                       */}
                            {(searchResult?.policyStatus == "EXPIRED" || calculateDaysLeftPercentage(searchResult?.expiryDate) <= 20) && <div className='d-inline dropdown'>
                                    <button type="button" class="btn btn-secondary dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Buy insurance now</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                        <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                    </div>
                                </div>
                                }
                            <button data-bs-dismiss="modal" aria-label="Close" type="button" class="btn btn-danger" style={{backgroundColor:"gray"}} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto" style={{maxWidth: "500px"}}>
                        <h1 class="display-6 mb-5">What They Say About Our Insurance</h1>
                    </div>
                    <div class="row g-5">
                        <div class="col-lg-3 d-none d-lg-block">
                            <div class="testimonial-left h-100">
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-1.jpg"
                                    alt="" />
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-2.jpg"
                                    alt=""
                                />
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-3.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div class="owl-carousel testimonial-carousel">
                                <div class="testimonial-item text-center">
                                    <img
                                        class="img-fluid rounded mx-auto mb-4"
                                        src="img/testimonial-1.jpg"
                                        alt=""
                                    />
                                    <p class="fs-5">
                                        Dolores sed duo clita tempor justo dolor et stet lorem kasd
                                        labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                                        et labore et tempor diam tempor erat.
                                    </p>
                                    <h5>Client Name</h5>
                                    <span>Profession</span>
                                </div>
                                <div class="testimonial-item text-center">
                                    <img
                                        class="img-fluid rounded mx-auto mb-4"
                                        src="img/testimonial-2.jpg"
                                        alt=""
                                    />
                                    <p class="fs-5">
                                        Dolores sed duo clita tempor justo dolor et stet lorem kasd
                                        labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                                        et labore et tempor diam tempor erat.
                                    </p>
                                    <h5>Client Name</h5>
                                    <span>Profession</span>
                                </div>
                                <div class="testimonial-item text-center">
                                    <img
                                        class="img-fluid rounded mx-auto mb-4"
                                        src="img/testimonial-3.jpg"
                                        alt=""
                                    />
                                    <p class="fs-5">
                                        Dolores sed duo clita tempor justo dolor et stet lorem kasd
                                        labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                                        et labore et tempor diam tempor erat.
                                    </p>
                                    <h5>Client Name</h5>
                                    <span>Profession</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 d-none d-lg-block">
                            <div class="testimonial-right h-100">
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-1.jpg"
                                    alt=""
                                />
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-2.jpg"
                                    alt=""
                                />
                                <img
                                    class="img-fluid animated pulse infinite"
                                    src="img/testimonial-3.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
