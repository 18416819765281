import axios from "axios";
// import CryptoJS from 'crypto-js';

export const baseURL = "https://api.insurbetter.com"
// export const baseURL = "http://localhost:8000/api"
const heirsUrl = "https://api.heirsinsurance.com/v1";
// const heirsUrl = "https://public-api.staging.heirsinsurance.com/v1"
const heirsSecret = "sg5wi087ox+E9TtgCUUSA2eqO/WV271zLrZht4ar01DMvtcz";
const heirsAppId = "7ef294a4-f527-4bab-8d10-def415bb3da8"
// const heirsSecret = "8fa070d3-e90f-4c33-834a-2b5e235a6b61";
// const heirsAppId = "cxkccuOcVRJm7G0BK9kW5D19gdcbARZT9UwZJ1XmKczX7qEn"

export const createPolicyHolder = async(data) => {
    return new Promise(async (resolve, reject) => {
        const requestData = {
            firstName: data.f_name,
            lastName: data.l_name,
            dateOfBirth: data.dateOfBirth,
            gender: data.gender?.toLowerCase(),
            phone: data.phone,
            occupation: data.occupation,
            email: data.email,
            idCardImgUrl: data.idCardImgUrl,
            utilityImgUrl: data.utilityImgUrl,
            city: "",
            state: "",
            address: data.address,
            country: "nigeria",
            street: "",
            streetNumber: "",
            postCode: "",
            number: data.idCardNumber?.toString(),
            expiry: data.idCardExpiry,
            type: data.idCardtype
        };
        
        // Define the headers for the request
        const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-App-Id': heirsAppId
        };
        
        // Make the POST request using Axios
        await axios.post(heirsUrl +'/policyholder', requestData, { headers })
        .then(response => {
            // Handle the successful response here
            console.log(response.data)
            resolve(response.data);
        })
        .catch(error => {
            // Handle errors here
            console.log(error)
            reject(error);
        });
    })
    
          
}

const dateFormater = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export const createPolicy = async(data,policyHolderId) => {
    return new Promise(async (resolve, reject) => {
        const requestData = {
            
            Name: data.name ,
            MobileNo: data.phone,
            Address: data.address,
            email: data.email,
            RegNo: data.reg_no,
            ChasisNo: data.vehicle_chassis,
            EngNo: data.vehicle_engineNumber,
            Make: data.vehicle_make,
            Model: data.vehicle_model,
            VehType: data.vehicle_type,
            VehYear: data.vehicle_year,
            Intcode: "TESTCODE",
            Password: "royal1234",
            PayRefNo: "vhhhgvhjghj",
            ProductCode: data.product_code,
            PmtAmt: "5000",
            PmtDate: dateFormater(),
            PmtChannel: "Paystack",
            grosspremium: data.gross_premium,
            netpremium: data.net_premium,
            suminsured: data.sum_insured,
        };

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://eportaltest.royalexchangeinsurance.com/api/v1/createpolicy',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : requestData
        };
        

        await axios.request(config)
            .then((response) => {
                console.log(response.data)
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });

    })
    
          
}

// const generateHMAC = (requestBody) => {
//     // const secretKey = '3BVAWahaLViRcV0+u7vjpKBaXUFhAK4r0KokTiX3EERoMiIw';
//     const secretKey = heirsSecret
//     // Convert the request body to a JSON string
//     const requestBodyString = JSON.stringify(requestBody);

//     // Generate the HMAC hash
//     const hmacHash = CryptoJS.HmacSHA512(requestBodyString, secretKey);

//     // Convert the hash to a hexadecimal string
//     const hexHash = hmacHash.toString(CryptoJS.enc.Hex);

//     console.log('SHA-512 HMAC Hash:', hexHash);

//     return hexHash;
// }

export const policyPayment = async (data,policyId) => 
{
    return new Promise(async (resolve, reject) => {
        const requestData = {"productId":data.productId,"referrer":"https://getinsage.com/success","policyId":policyId,"multiple":1,"method":"paystack"};
        console.log(requestData)
        
        // Define the headers for the request
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Heirs-Signature': generateHMAC(requestData),
            'X-App-Id': heirsAppId
        }; 
        
        // Make the POST request using Axios
        await axios.post(heirsUrl+'/Heirs%20Insurance/policy/pay', requestData, { headers })
        .then(response => {
            // Handle the successful response here
            console.log(response.data)
            resolve(response.data);
        })
        .catch(error => {
            // Handle errors here
            console.log(error)
            reject(error);
        });
    })
}

export const getCarMakes =  () => {
    return new Promise(async (resolve,reject) => {
        const options = {
            method: 'GET',
            url: 'https://car-data.p.rapidapi.com/cars/makes',
            headers: {
              'X-RapidAPI-Key': '6b07190f57mshbbc9ff1db52e792p117c70jsne828ac3c65dd',
              'X-RapidAPI-Host': 'car-data.p.rapidapi.com'
            }
        };

        const response = await axios.request(options)
        .then(response => {
            // Handle the successful response here
            console.log(response.data)
            resolve(response.data);
        })
        .catch(error => {
            // Handle errors here
            console.log(error)
            reject(error);
        });;

          
    })
}

export const getCarModel =  (model) => {
    return new Promise(async (resolve,reject) => {
        const options = {
            method: 'GET',
            url: `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${model}?format=json`,
        };

        const response = await axios.request(options)
        .then(response => {
            // Handle the successful response here
            console.log(response.data.Results)
            resolve(response.data.Results);
        })
        .catch(error => {
            // Handle errors here
            console.log(error)
            reject(error);
        });;

          
    })
}