import React,{useEffect} from 'react'



export default function HowToBuy() {

  useEffect(()=>{
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' for an instant scroll
        });
    };
    scrollToTop();
},[])

  return (
    <>
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"   >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">How to buy</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">How to buy</li>
                </ol>
                </nav>
            </div>
        </div>
        <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center mx-auto" style={{maxWidth: "700px"}}>
          <h1 class="display-6 mb-5">
            Buy third party and comprehensive insurance online with 
            Insurbetter
          </h1>
        </div>
        <div class="row g-4 justify-content-center">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item rounded h-100 p-5">
              <div class="d-flex align-items-center ms-n5 mb-4">
                <div
                  class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                > 
                  <h1 class="display-5 text-white">1</h1>
                </div>
                <h4 class="mb-0">Buy your motor insurance...
                </h4>
              </div>
              <ul>
                <li>Visit our home page</li>
                <li>Select “Buy Motor Insurance"</li>
                <li>Select Third Party or Comprehensive</li>
                <li>Provide the relevant details</li>
                <li>Submit and pay</li>
              </ul>
              <p class="mb-4">
                Alternatively, you can buy insurance by sending all requirements and pictures of the vehicles (side, front, back and aerial vehicle of the vehicle) to our email address (contactus@eclatinsage.com)
              </p>
              <a class="btn btn-light px-3" href="">Read More</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item rounded h-100 p-5">
              <div class="d-flex align-items-center ms-n5 mb-4">
                <div
                  class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                >
                  <h1 class="display-5 text-white">2</h1>
                </div>
                <h4 class="mb-0">Upload the required documents...</h4>
              </div>
              
              <p class="mb-4">
                Third Party Documentation Requirements
              </p>
              <ul>
                <li>Valid means of ID (Any one of the following is acceptable; Drivers License, Int’l Passport, Voters Card, NIMC/NIN Card, Tax ID) </li>
              </ul>
              <p class="mb-4">
                Comprehensive Documentation Requirements
              </p>
              <ul>
                <li>Valid means of ID (Any one of the following is acceptable; Drivers License, Int’l Passport, Voters Card, NIMC/NIN Card, Tax ID) </li>
                <li>Proof of vehicle ownership (Autoreg vehicle registration)</li>
                <li>Utility bill</li>
              </ul>
              <a class="btn btn-light px-3" href="">Read More</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item rounded h-100 p-5">
              <div class="d-flex align-items-center ms-n5 mb-4">
                <div
                  class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                >
                  <h1 class="display-5 text-white">3</h1>
                </div>
                <h4 class="mb-0">Receive insurance policy and certificate...</h4>
              </div>
              
              <p class="mb-4">
                Two minutes after completing payment for insurance policy your certificate and policy would be sent to your provided email address
              </p>
              
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </>
  )
}
