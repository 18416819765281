import React,{useEffect} from 'react'

export default function Succcess() {

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])
    
  return (
    <>
        <div className='page-header py-5 mb-5 wow fadeIn'>

        </div>

        <main className=' py-5 mb-5 wow fadeIn'>
            <div class="hero-area2  slider-height2 hero-overly2 d-flex align-items-center ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center pt-50">
                                    <h1 className='text-success'>Your Transaction was Successful...</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </main>
    </>
    
  )
}
