import React from 'react';
import Nav from '../Layout/Nav';
import Footer from '../Layout/Footer';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';


const Index = () => {
    return (
        <>
           <Nav></Nav>
           <Outlet></Outlet> 
           <Footer></Footer>

           <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                    background: '#363636',
                    color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                    },
                }}
                />
        </>
    );
}

export default Index;
