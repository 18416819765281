import React,{useState,useRef, useEffect} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

export default function VerifyPolicy() {

    let loadingToast;
    const modalBtnRef = useRef();
    const searchBtnRef = useRef();
    const closeBtnRef = useRef()
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [formControls, setFormControls] =  useState({
        'search_by':'Registration',
        'search_value':''
    });

    const [searchResult, setSearchResult] = useState()

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[])   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        loadingToast = toast.loading('Loading....')

        let postData = {
            sv: formControls.search_value,
            st: formControls.search_by.toUpperCase()+"NUMBER",
            un: 'eil',
            pw :'tpKh7iVx9cxl1ZD',
        }
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://rest.niid.org/api/motorinsurance/verify?sv=${postData.sv}&st=${postData.st}&un=eil&pw=tpKh7iVx9cxl1ZD`,
            
        };
        let request = await axios.request(config)
                        .then((resp) => {
                            console.log(resp)
                            setSearchResult(resp?.data)
                            if(resp?.data?.policyStatus ==  "NOT FOUND"){
                                toast.dismiss(loadingToast);
                                toast.error("Your policy was not found on niid portal, kindly check with your insurance company or buy a valid insurance.");
                                searchBtnRef.current?.click()
                                setLoading(false)
                            }else{
                                console.log(searchBtnRef.current)
                                searchBtnRef.current?.click()
                                toast.dismiss(loadingToast);
                                setLoading(false)
                            }
                           
                        }).catch((e) =>{
                            toast.dismiss(loadingToast);
                            console.log(e)
                            toast.error(e?.message);
                            setLoading(false)
                         }
                    )
    }

    function calculateDaysLeftPercentage(expireDate) {
        // Parse the expireDate string to a Date object
        const expireDateObj = new Date(expireDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const timeDiff = expireDateObj - currentDate;

        // Calculate the number of days left
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Calculate the percentage of days left
        const percentageLeft = (daysLeft / 365) * 100;

        // Ensure the result is within the 0-100 range
        return Math.floor(Math.min(100, Math.max(0, percentageLeft)));
    }


    const buyInsurance = (value,state) => {
        closeBtnRef.current.click();
        navigate(value,{state:state});
    }

  return (
    <>
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">Verify Policy</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Verify Policy    </li>
                </ol>
                </nav>
            </div>
        </div>

        <div id="search" class="container-fluid appointment my-5 py-5 wow fadeIn" data-wow-delay="0.1s" >
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-6 wow fadeIn offset-lg-3 text-center" data-wow-delay="0.3s">
                        <h1 class="display-6 text-white mb-2">
                            Verify Your Policy
                        </h1>
                        <p class="text-white mb-1">
                            To check the validity of your Insurance Policy, search by Policy Number or Registration Number.
                        </p>
                        
                    </div>
                    <div class="col-lg-6 wow fadeIn offset-lg-3" data-wow-delay="0.5s">
                        <div class="bg-white rounded p-5">
                            <form onSubmit={(e) =>handleSubmit(e)}>
                                <div class="row g-3">
                                    <div class="col-sm-12">
                                        <div class="form-floating">
                                            <select style={{fontSize:'16px'}} onChange={(e) => setFormControls({...formControls,'search_by':e.target.value})} required class="form-control">
                                                <option value="Registration" selected>Vehicle Plate Number</option>
                                                <option value="Policy" >Policy Number</option>
                                            </select>
                                            <label style={{fontSize:'16px'}}  for="gname">Search By</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="cname"
                                                style={{fontSize:'18px'}} 
                                                required onChange={(e) => setFormControls({...formControls,'search_value':e.target.value})} placeholder={`Enter ${formControls.search_by ? formControls.search_by :  " Policy / Vehicle Plate " } Number`}
                                            />
                                            <label style={{fontSize:'18px'}}  for="cname">{`Enter ${formControls.search_by == 'Registration'?  'Vehicle Plate' :  " Policy " } Number`}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        {loading == false && <button class="btn btn-primary py-3 px-5" type="submit" style={{fontSize:'18px'}} >
                                            Search Policy
                                        </button>}
                                    </div>
                                </div>
                            </form>
                            
                        </div>
                        <div class="bg-white rounded p-3 d-block d-lg-none mt-5 ">
                            <div class="d-flex align-items-center bg-primary rounded p-3">
                            <img
                                class="flex-shrink-0 rounded-circle me-3"
                                src="img/user_Avatar.jpeg"
                                width={60}
                                alt=""
                            />
                                <h5 class="text-white mb-0">Call Us: <a className='text-white' href="mailto:+2349024868268">+2349024868268</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-primary d-none"  ref={searchBtnRef}  data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" >Search Result</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {searchResult?.policyStatus == "EXPIRED" && <h3 className=''>Your Insurance Policy has <b className='text-danger'>EXPIRED</b></h3>}

                            {searchResult?.policyStatus == "NOT FOUND" && <h5 className=''>
                                Your Insurance Policy was <b className='text-danger text-uppercase'>not found</b> on NIID portal, kindly check with your insurance company or  
                                <div className='d-inline dropdown'>
                                    <a className='btn-link dropdown-toggle' href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Buy Motor Insurance</a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                        <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                    </div>
                                </div>          
                            </h5>}

                            {searchResult?.policyStatus == "OK" && <ul className='mb-30'>
                                <li>Policy Status: <span className='text-success text-bold ' style={{fontSize:"20px"}}>Your Policy is Active</span> </li>
                                <li>Registration no: <b>{searchResult?.regNo}</b> </li>
                                <li>Policy no: <b>{searchResult?.policyNumber}</b></li>
                                <li>Chassis no: <b>{searchResult?.chassis}</b></li>
                                <li>Date Issuerd: <b>{searchResult?.issueDate}</b></li>
                                <li>Expiry Date: <b>{searchResult?.expiryDate}</b></li>
                                <li style={{display:"flex", alignItems:'center'}}>
                                    <p style={{flexShrink: 0, marginRight:"15px", marginBottom:"0px", color:'#212529'}}> Expiry Status:  </p>  
                                    <div style={{display: "inline-block", width:"100%"}} class=" progress" role="progressbar" aria-label="Danger example" aria-valuenow={calculateDaysLeftPercentage(searchResult?.expiryDate)} aria-valuemin="0" aria-valuemax="100">
                                        <div class={(calculateDaysLeftPercentage(searchResult?.expiryDate) > 20? 'bg-success ':'bg-danger ')+ "progress-bar "} style={{width: calculateDaysLeftPercentage(searchResult?.expiryDate) +"%"}}>{calculateDaysLeftPercentage(searchResult?.expiryDate)}%</div>
                                    </div>
                                </li>
                                <li>Insurance Policy Type: <b>{searchResult?.coverType == "T"? "Third Party Motor Insurance": "Comprehensive Motor Insurance" } </b></li>
                                <li>Vehicle make: <b>{searchResult?.vehicleMake}</b></li>
                                <li>Vehicle model: <b>{searchResult?.vehicleModel}</b></li>
                            </ul>}
                        </div>
                        <div class="modal-footer">
                            {/* {
                                loading? 
                                (<button type="button" class="btn btn-secondary">Loading</button>) :
                                (<button onClick={() => formControls?.type == "third-party" ? handleProceedToPay2() : ("")} type="button" class="btn btn-secondary">Proceeed to pay</button>)
                            }                       */}
                            {(searchResult?.policyStatus == "EXPIRED" || calculateDaysLeftPercentage(searchResult?.expiryDate) <= 20) && <div className='d-inline dropdown'>
                                    <button type="button" class="btn btn-secondary dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Buy insurance now</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <button  class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/third-party-Insurance',searchResult)}>Third Party Insurance</button>
                                        <button class="dropdown-item p-2 m-0" onClick={()=>buyInsurance('/comprehensive-Insurance',searchResult)} >Comprehensive Insurance</button>
                                    </div>
                                </div>
                                }
                            <button data-bs-dismiss="modal" aria-label="Close" type="button" class="btn btn-danger" style={{backgroundColor:"gray"}} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
