import logo from './logo.svg';
import './App.css';
import Nav from './Layout/Nav';
import Footer from './Layout/Footer';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from './Pages';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import HowToBuy from './Pages/HowToBuy';
import ThirdPartyInssurance from './Pages/ThirdPartyInssurance';
import ComprehensiveInsurance from './Pages/ComprehensiveInsurance';
import Succcess from './Pages/Succcess';
import Error from './Pages/Error';
import VerifyPolicy from './Pages/VerifyPolicy';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}>
            <Route index element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/how-to-buy'  element={<HowToBuy />}/>
            <Route path='/contact'  element={<Contact />}/>
            <Route path='/verify-policy'  element={<VerifyPolicy />}/>
            <Route path='/third-party-Insurance'  element={<ThirdPartyInssurance />}/>
            <Route path='/comprehensive-Insurance'  element={<ComprehensiveInsurance />}/>
            <Route path='/success'  element={<Succcess />}/>
            <Route path='/error'  element={<Error />}/>
          </Route>
        </Routes>
        
      </BrowserRouter>
    </>
  );
}

export default App;
